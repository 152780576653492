<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            Actualmente en clínica
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadView++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary">
                    <v-tab href="#tab-1">
                        ACTUALMENTE EN CLINICA
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <block v-if="loading"></block>
                        <v-card flat>
                            <v-card-text class="p-0">
                                <v-row class="background border-box">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-btn class="btn-add mt-8 ml-4 mb-4" @click="getPatientsLists">Actualizar lista de espera</v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-checkbox class="mt-8 ml-4 mb-4" @click="reloadDataPatients" label="Ver despachados" />
                                        </v-col>
                                    </v-row>
                                    <v-col cols="12">
                                        <date-filter class=" mt-3" v-if="!loading" @filtered-data="dateFilterHandler" :dataToFilter="patientsLists" :search="{ ...search }" :filterEndpoint="$API.patientslists.getDayPatients" :isFilteredByOutsource="false"/>
                                        <GeneralFilter class="background border-box" :filterEndpoint="$API.patientslists.getDayPatients" :search="{ ...search }" @emptyFilter="getPatientsLists" @filtered="filterHandler">
                                            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="patientsLists" style="max-height: 600px;"
                                                        :show-row-lines="true"
                                                        :show-borders="true"
                                                        :row-alternation-enabled="true"
                                                        :scrolling="{ useNative: true }"    
                                                        :column-auto-width="true" 
                                                        :paging="{ enabled: false }" >
                                                <!--DxPaging :page-size="30"/-->
                                                <DxScrolling row-rendering-mode="virtual"/>
                                                <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                                <DxColumn :width="130" cell-template="action-template" caption=""></DxColumn>
                                                <DxColumn data-field="VisitDateFormatted" caption="Fecha"></DxColumn>
                                                <DxColumn data-field="VisitTime" caption="Hora de registro"></DxColumn>
                                                <DxColumn data-field="NamePatient" caption="Nombre Paciente"></DxColumn>
                                                <DxColumn data-field="VisitData" caption="Motivo"></DxColumn>
                                                <DxColumn data-field="Status" caption="Estado"></DxColumn>
                                                <DxColumn data-field="NameCustomer" caption="Nombre Cliente"></DxColumn>
                                                <DxColumn data-field="AsignedTo" caption="Dr. Asignado"></DxColumn>
                                                <DxColumn data-field="ID_Patient" caption="ID de Paciente"></DxColumn>
                                                <DxColumn data-field="ID_Customer" caption="ID de Cliente"></DxColumn>
                                                <template #show-template="{data}">
                                                    <template>
                                                        <a href="#" class="mx-1" @click="patientSelected(data.data)">
                                                            <v-icon color="primary">mdi-eye</v-icon>
                                                        </a>
                                                    </template>
                                                </template>
                                                <template #action-template="{ data }">
                                                    <template>
                                                        <v-btn class="btn-add" @click="updateStatusClinic(data.data)">Despachar</v-btn>
                                                    </template>
                                                </template>
                                                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[30, 50, 100]" />
                                            </DxDataGrid>
                                        </GeneralFilter>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
                </alerts>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling, DxPager } from 'devextreme-vue/data-grid';
import Block from "@/components/Block";
import GeneralFilter from '@/components/GeneralFilter.vue';
import Alerts from '@/components/Alerts';
import DateFilter from '@/components/DateFilter.vue';

export default {
    name: "PatientClinic",
    components: { DxDataGrid, DxColumn, DxScrolling, Block, GeneralFilter, Alerts, DateFilter, DxPager },
    props: ["win"],
    data() {
        return {
            tab: "tab-1",
            reloadView: 0,
            patientsLists: [],
            loading: false,
            alert: {
                type: null,
                show: false,
                header: "",
                body: "",
            },
            search: {
                isSeeDispatched: false
            }
        }
    },
    mounted() {
        this.getPatientsLists()
    },
    methods: {
        getPatientsLists() {
            this.loading = true
            this.$API.patientslists.getDayPatients({ ...this.search }).then(response => {
                this.patientsLists = response
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        filterHandler(response) {
            this.patientsLists = response
        },
        dateFilterHandler(filteredData) {
            this.patientsLists = filteredData;
        },
        async patientSelected(data) {
            try {
                const customer = await this.$API.customers.getOneCustomer(data.ID_Customer);
                let patient = await this.$API.patients.getOne({id: data.ID_Patient, customer_id: data.ID_Customer});
                patient.customer = customer;

                this.$store.dispatch("addWindow", {
                    name: "PatientData",
                    component: "PatientData",
                    unique: false,
                    meta: { patient: patient },
                });
                this.$store.commit("patientData/changeView", "tab-1")
            } catch (e) {
                console.log(e);
            }
        },
        async updateStatusClinic(data) {
            try {
                this.loading = true
                let result = await this.$API.patientslists.updateStatusClinic(data)
                if (result?.status == "OK") {
                    this.showAlert("success", "Exito", "El paciente ha sido despachado.")
                }
                else {
                    throw new Error('Error')
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.showAlert("danger", "Error", "Ha ocurrido un problema, por favor intente nuevamente.")
                this.loading = false
            }

            this.getPatientsLists()
        },
        showAlert(type, header, body, options = null){
            type = (type == null) ? "danger" : type
            this.alert.type = type
            this.alert.header = header
            this.alert.body = body
            this.alert.show = true
            this.alert.options = (options != null) ? options : null
        },
        closeAlert() {
            this.alert.show = false
        },
        reloadDataPatients(){
            this.search.isSeeDispatched = !this.search.isSeeDispatched
            this.getPatientsLists() 
        },
    }
}
</script>
<style>
.btndelete {
    background-color: #E3191E !important;
    color: white;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.border-box {
    border-radius: 15px !important;
}

.background {
    background: white !important;
    color: white;
}
</style>